//First active fun
//Before Youtube API


/**
 * @license
 * Copyright 2021 Google LLC. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */

 import '@tensorflow/tfjs-backend-webgl';

 import * as tfjsWasm from '@tensorflow/tfjs-backend-wasm';

import * as posedetection from '@tensorflow-models/pose-detection';

//import * as tf from '@tensorflow/tfjs';
 
 import {Camera} from './camera';
 
 import {STATE} from './params';

 import configData from "./config.json";
import { time } from '@tensorflow/tfjs-core';
import { stat } from 'browserify-fs';


 tfjsWasm.setWasmPaths(
  `https://cdn.jsdelivr.net/npm/@tensorflow/tfjs-backend-wasm@${
      tfjsWasm.version_wasm}/dist/`);
 
var stats = require("stats-lite");

let detector, camera, net,dObj;

let rafId;
var arryLen; 
var data, startTime=-1, timeLapsed=0, b_timer,ls_log="";

var ln_utterSpeed = 1,ln_promptTime=-11, ln_configRA=15, n_stage=0,n_jsShoulderCheck=0 
,n_exStartTime=0,n_gameDuration=120,n_poseLookBack=7
,b_SI=true, b_notSpeaking=true,s_comment="", s_prevComment="", n_lastCommentTime=-1,n_sceneID,n_predictID

,ad_effect,ad_effectHit, n_direction=1, n_systemStartUpTime=5,n_stablePtsDuration=2,n_exDuration=20, n_numPtToAvg=1
,n_trendCount = 3, n_imgToReadingOffset=0
//assuming a trend is only confirmed with a consistent pattern of 0.52sec. poll interval 0.13
, o_criteriaValues, arry_start=[], arry_end=[], arry_thru=[],ad_background,n_stableStart=0, n_DKpOffSet=0, n_modelType=0, b_showSkeleton=false
,n_DKpIndex=-1,n_repCount=0,arryPoseSeq=[],n_phase=0, n_stabilityThreshold=3.5, exObj;//0 not started, 1 in 1st half, 2 in 2nd half,  

var drawingBoard,dbCtx,nCanvasWidth=960,nCanvasHeight=760, nShapeWidth=0,nShapeHeight=0,n_refreshRate=193,n_intervalPS=0, n_animateDuration=2
//,arryPoseImg=[]
, n_animationFactor=0, b_initLoad=true, b_clickedDance = false, s_songSelected="",s_sessionID,n_commentID;

var  audioCtx = new AudioContext(),song = new Audio(), source, gainNode, soundEffect=new Audio();


const h_partsIndex = new Map();


var synth = window.speechSynthesis;
var utterThis = new SpeechSynthesisUtterance();
var soundDuration = {jc:0.5,jf:1.5, running:1 };
var soundEndTracking = {jc:-1,jf:-1,running:-1};


function setComment(aTimeLapsed, aCommentStr, abOverwrite){

  logIt("s_prevComment: " + s_prevComment + " aCommentStr: " + aCommentStr 
  + " n_lastCommentTime: " + n_lastCommentTime + " ln_configRA: " + ln_configRA);

  if (typeof(aCommentStr) === "undefined"){
    logIt("detected undefined str");
  }else
  if(aTimeLapsed - n_lastCommentTime > ln_configRA || s_prevComment!==aCommentStr){

    logIt("Set comment: " + aCommentStr);

    if (s_comment.length===0)
      s_comment = aCommentStr;
    else{

      if (abOverwrite){

        s_comment = aCommentStr;

      }

    }

  }

}

/*

async function justComment(){

  if(s_comment.length>0){

    sayComment(timeLapsed);


  }

}

*/


function sayComment(aTimeLapsed){

  if (s_comment.length>0){

    instructNow(s_comment);
    s_prevComment = s_comment;
    n_lastCommentTime = aTimeLapsed;

    s_comment = "";
    logIt(timeLapsed, "comment is "+s_comment+" cleared: ");
   

  }

}

function PlaySound(as_obj, a_sFileName, ab_loop){

  // as_obj = new Audio(a_sFileName);
   as_obj.src = a_sFileName
   as_obj.loop = ab_loop;
   as_obj.play(); 
 }

function setUpDeviceParam(){

  var ln_vWidth=document.getElementById('video').videoWidth; 

  var ln_vHeight=document.getElementById('video').videoHeight; 
  var n_scaleFactor = 0;

  var b_isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;

  logIt("is it mobile:" +b_isMobile);

  logIt("video size: width:" + ln_vWidth + " height:" + ln_vHeight);
  logIt("screen size: width:" + window.screen.width + " height:" + window.screen.height);
      
  if (window.screen.height > window.screen.width ){ // portrait


    if (ln_vWidth > window.screen.width){

      document.getElementById('canvasContainer').style.width = window.screen.width+"px";
      document.getElementById('canvasContainer').style.height = window.screen.height+"px";
  
      if(window.screen.width < ln_vWidth){
        n_scaleFactor = window.screen.width/ln_vWidth;
      }

    }

  }else {

    if (window.screen.height>=720 && window.screen.width>=960 ){


     // document.getElementById('canvasContainer').style.width ="960px";
    //  document.getElementById('canvasContainer').style.height = "720px";

      n_scaleFactor = 960/ln_vWidth; 
    }else if (window.screen.height >= ln_vHeight && window.screen.width >= ln_vWidth){
      n_scaleFactor=1;
     // document.getElementById('canvasContainer').style.width =ln_vWidth+"px";
     // document.getElementById('canvasContainer').style.height = ln_vHeight+"px";

    }else{

    //  document.getElementById('canvasContainer').style.width = window.screen.width+"px";
    //  document.getElementById('canvasContainer').style.height = window.screen.height+"px";

      if(window.screen.width < ln_vWidth){
        n_scaleFactor = window.screen.width/ln_vWidth;
      }
    }


    document.getElementById('canvasContainer').scrollIntoView({
      behavior: 'auto',
      block: 'center',
      inline: 'center'
    });
    

  }

  logIt("scale factor:" +n_scaleFactor);
  return {n_videoWidth:ln_vWidth,n_videoHeight:ln_vHeight,n_scaleFactor: n_scaleFactor, b_isMobile: b_isMobile }
      //document.getElementById('sLevel').innerHTML = "ln_vWidth:" + ln_vWidth + ", ln_vHeight:" + ln_vHeight;
  
}

function instructNow(instStr){

  b_notSpeaking = true;
  ln_promptTime = timeLapsed - ln_configRA -1;

  instruct(instStr);


}

function instruct(instStr){

  instructBase(instStr, ln_configRA);

}

function instructBase(instStr, an_waitTime){

  logIt("in instruct for: " + instStr);
  logIt(" with b_notSpeaking:" + b_notSpeaking );
 

  if (b_notSpeaking && timeLapsed-ln_promptTime > an_waitTime){
    b_notSpeaking = false;
    

    if (b_SI){


      utterThis.text =instStr;
      utterThis.rate = ln_utterSpeed;
      utterThis.volume = 1;
      utterThis.onend = function(){
        ln_promptTime = timeLapsed;
        b_notSpeaking = true;
        logIt(" now b_notSpeaking:" + b_notSpeaking );

      };
      ln_promptTime = timeLapsed;
      synth.speak(utterThis);
      logIt("spoke: " + instStr);
      
    }
  
    return true;
    
  }else
    return false;

}

function creImage(imgName,x,y,w,h){

  var imgC = document.createElement('img');
  imgC.src = imgName;

  
  dbCtx.drawImage(imgC,x,y,w,h);
  //camera.drawPic(imgC,{x:x,y:y},w,h);

}
/// End Display and Sound Functions

function logIt(als_log){

  //var myTimeLapsed = (Date.now() - startTime)/1000;
 
   ls_log += timeLapsed+": "+als_log + "\\n";
   //ls_log="";
 }
 

 /// Start Stats function

 function generateRandomIntegerInRange(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

 function getDistance(p1, p2){

  return Math.sqrt(Math.pow((p1.x-p2.x),2) + Math.pow((p1.y-p2.y),2));

}

function toDegrees (angle) {
  return angle * (180 / Math.PI);
}

 function getANGby3Points(s, eb, w){

  var Dseb, Debw,Dsw;
  
  Dseb = getDistance(s, eb);
  Debw = getDistance(eb, w);
  Dsw = getDistance(s, w);

  //document.getElementById('angle').innerHTML = "Dseb2: " + (Dseb*Dseb) + " Debw2: " + (Debw*Debw) + " Dsw2: " + (Dsw*Dsw);

  if (Dsw+Debw+Dseb ===0 || Dseb===0||Debw===0){

    return 370;
  }else{


    return toDegrees(Math.acos((Dseb*Dseb + Debw*Debw-Dsw*Dsw)/(2*Dseb*Debw)));

    //return (Dsw/(Dsw+Debw+Dseb)) * 180;
  }

}

function getSignedANGXY(pp,np){

  if (pp.y-np.y ===0)
  return 90;
else
  return Math.atan((pp.x-np.x)/(pp.y-np.y)) * 180 / Math.PI;

}

function getUnSignedANGbyLength(aHeight, aWidth){

  logIt("in Unsigned Ang by length height: " + aHeight + " width: " + aWidth);

  if (aHeight === 0)
    return 0;
  else 
    return  Math.atan(aHeight/aWidth) * 180 / Math.PI;

}

 function getArrayAvg(aarry, an_poseIndex, as_property){

  var tmp_arry=[];

  for (var t=0; t< aarry.length; t++){
   
    if (as_property==="x")
      tmp_arry.push(aarry[t][an_poseIndex].x);
    else if (as_property==="x")
      tmp_arry.push(aarry[t][an_poseIndex].y);
    else 
      tmp_arry.push(aarry[t][an_poseIndex].score);

  }

  //logIt("length arry: " + aarry.length  + " avg tmp_arryX:" +stats.mean(tmp_arryX) );

  return stats.mean(tmp_arry);

}

function getTrendY(aarry, anCheckLevel){ // asTrend: 'min' true when values get bigger; eg. diff +v;   for  can evolve into checkXTrend

  logIt("in checkYTrend with anCheckLevel:" + anCheckLevel );

  if ((anCheckLevel + 1) < aarry.length && anCheckLevel>0){

    var n1stTrend = (Math.floor(aarry[aarry.length-1][n_DKpIndex].y) - Math.floor(aarry[aarry.length-2][n_DKpIndex].y));

    
    logIt("first Diff: " +n1stTrend + "n_dKp:"+n_DKpIndex +" with index " + (aarry.length-1) +" yvalue :"+aarry[aarry.length-1][n_DKpIndex].y
    + " and index " + (aarry.length-2) +" yvalue :"+aarry[aarry.length-2][n_DKpIndex].y);

    var nReturnValue = 0

    if (n1stTrend > 1)
      nReturnValue = 1;
    else if (n1stTrend < -1)
      nReturnValue =-1;

    if (nReturnValue=== 0){
      //bf 22Sept
      let poppedEle = arryPoseSeq.pop();
      //arryPoseImg.pop();
      logIt("Popped Dkp y value:"+ poppedEle[n_DKpIndex].y)
      //aarry.pop();
      //Endbf

      return -9;
    }


    for (let i=1; i<anCheckLevel;i++){

      let arryTempTrendRaw = [];

       //implement 2pt avg
      arryTempTrendRaw.push(aarry[aarry.length-1-i][n_DKpIndex].y - aarry[aarry.length-2-i][n_DKpIndex].y);
      arryTempTrendRaw.push(aarry[aarry.length-2-i][n_DKpIndex].y - aarry[aarry.length-3-i][n_DKpIndex].y);

      logIt(i+ " Diff: " +(aarry[aarry.length-1-i][n_DKpIndex].y - aarry[aarry.length-2-i][n_DKpIndex].y));
      logIt(i+ " Diff2: " + (aarry[aarry.length-2-i][n_DKpIndex].y - aarry[aarry.length-3-i][n_DKpIndex].y));
      logIt("Avg: " + Math.floor(stats.mean(arryTempTrendRaw)));



      if (nReturnValue > 0 ){

        if (Math.floor(stats.mean(arryTempTrendRaw))<0){
          logIt(i+" inconsistent in +ve trend return 0");
          return 0;
        }

      }else{ // nReturnValue<0

        if (Math.floor(stats.mean(arryTempTrendRaw))>0){
          logIt(i+" inconsistent in -ve trend return 0");
          return 0;
        }


      }  

    }

    return nReturnValue;


  }else 
    return -9;
    

}

function getArrayStdev(aarry, an_poseIndex, as_property){

  var tmp_arry=[];

  for (var t=0; t< aarry.length; t++){
   
    if (as_property==="x")
      tmp_arry.push(aarry[t][an_poseIndex].x);
    else
      tmp_arry.push(aarry[t][an_poseIndex].y);

  }

  //logIt("length arry: " + aarry.length  + " avg tmp_arryX:" +stats.mean(tmp_arryX) );

  return stats.stdev(tmp_arry);

}

 /// End Stats functions

/// Start Pose Logic functions

//import from Birdy a more powerful one
function getPL(p, an_scaleX, an_scaleY){

  var ap_l = [];
  an_scaleX=1;
  an_scaleX=1;
 
  if (p[12].x > p[11].x){
    p[0].x = p[0].x/an_scaleX;
    p[0].y = p[0].y/an_scaleY;
    ap_l.push(p[0]);
    for (var i=1; i < 17; i++){


      if (i%2===0){
        p[i-1].x = p[i-1].x/an_scaleX;
        p[i-1].y = p[i-1].y/an_scaleY;
        ap_l.push(p[i-1]);
        //ap_l[i] = p[i-1];

      }else{
        p[i+1].x = p[i+1].x/an_scaleX;
        p[i+1].y = p[i+1].y/an_scaleY;
        ap_l.push(p[i+1]);
        //ap_l[i] = p[i+1];
      }

    }

  }else{
    ap_l = p;
  }

  return ap_l;

}

function checkPartsStability(aarryPoseSeq,aarryIndiceToCheck,anStdev){
  
  logIt("in checkPartsStablility");

    for (let nIndex of aarryIndiceToCheck){

      logIt("index: " + nIndex + " y:" +getArrayStdev(aarryPoseSeq,nIndex,"y") + " x:" + getArrayStdev(aarryPoseSeq,nIndex,"x") );

      if (getArrayStdev(aarryPoseSeq,nIndex,"y")>anStdev || getArrayStdev(aarryPoseSeq,nIndex,"x")>anStdev){

        logIt("index: " + nIndex + " not stable. y: "+getArrayStdev(aarryPoseSeq,nIndex,"y") + " x: " + getArrayStdev(aarryPoseSeq,nIndex,"x"));
        return false;

      }

  }
  logIt("Parts are stable");

  

  return true;

}

function checkWholeBodyStability(aarryPoseSeq,anStdev){

  logIt("in check whole body stable");
  let arryBodyIndice = [0,2,5,6];
  
  /*
// left ankle
   && getArrayStdev(aarryPoseSeq,12,"y")<anStdev && getArrayStdev(aarryPoseSeq,12,"x")<anStdev //right hip
  && getArrayStdev(aarryPoseSeq,8,"y")<anStdev && getArrayStdev(aarryPoseSeq,8,"x")<anStdev // right elbow
  && getArrayStdev(aarryPoseSeq,9,"y")<anStdev && getArrayStdev(aarryPoseSeq,9,"x")<anStdev  //left wrist

  */

  return checkPartsStability(aarryPoseSeq,arryBodyIndice,anStdev)


}

function checkSplitShoulder(aCpl, anRightForward, anLeftForward, anRep){

  var nlCalfY = aCpl[15].y - aCpl[13].y;
  var nrCalfY = aCpl[16].y - aCpl[14].y;

  var nCurrRatio = nlCalfY/nrCalfY;

  logIt("checkBentKnees ratio: "+  nCurrRatio +" anRightForward: "+  anRightForward+ " rsY: " + aCpl[6].y + " lsY: " + aCpl[5].y 
  + " n_jsShoulderCheck: " + n_jsShoulderCheck + " anRep: " + anRep);

  if (anRep%2===0){ // even Rep right knee forward

    logIt("check condition nCurrRatio < anRightForward: " + (nCurrRatio < anRightForward));
    if (nCurrRatio < anRightForward){

      if (aCpl[6].y <  aCpl[5].y )//
        return 1; // right knee bent forward
      else{
        n_jsShoulderCheck++;
        if (n_jsShoulderCheck > 2)
          setComment(timeLapsed,"Right shoulder forward");
      }

    }else if (nCurrRatio > anLeftForward){

      return -1; // left knee bent forward

    }

  }else{ // odd rep left knee forward

    logIt("check condition nCurrRatio < anRightForward: " + (nCurrRatio < anRightForward));
    if (nCurrRatio < anRightForward){

      return -1; // right knee bent forward

    }else if (nCurrRatio > anLeftForward){

      if (aCpl[6].y >  aCpl[5].y )
        return 1; // left knee bent forward
      else{
        n_jsShoulderCheck++;
        if (n_jsShoulderCheck > 2)
          setComment(timeLapsed,"Left shoulder forward");
      }

    }

  }

  return 0;

}



/// End Pose Logic

/// Start video capturing/ AI

async function createDetector() {

  STATE.model = posedetection.SupportedModels.MoveNet;
  let modelType;
  if (n_modelType===0){

    modelType= posedetection.movenet.modelType.SINGLEPOSE_LIGHTNING;
    

  }else{
    
    modelType= posedetection.movenet.modelType.SINGLEPOSE_THUNDER;

  }

  const modelConfig = {modelType};
  modelConfig.enableTracking = STATE.modelConfig.enableTracking;

  return posedetection.createDetector(STATE.model, modelConfig);


}

function sendSuggestion(aFileName,aSuggestion){

  b_timer = false;
    
  const axios = require('axios');

  var currentdate = new Date();
  var newFileName = aFileName
  +"_"+ currentdate.getDate() + "_"
  + (currentdate.getMonth()+1)  + "_" 
  + "t"  
  + currentdate.getHours() + "_"  
  + currentdate.getMinutes() + "_" 
  + currentdate.getSeconds();
  var bodyFormData = new FormData();

  bodyFormData.append('afileName', newFileName);
  bodyFormData.append('csvData', "data");
  bodyFormData.append('csvLog', aSuggestion);

  var s_url="";

  s_url = "https://csm2.achieversprofile.com/c/d?handler=Log";


  axios({
    method: "post",
    url: s_url,
   
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  })
    .then(function (response) {

      logIt(response);

    })
    .catch(function (response) {

      logIt(response+" errr");

    });

}



function sendData(aFileName){

  b_timer = false;
    
  const axios = require('axios');

  var currentdate = new Date();
  var newFileName = aFileName
  +"_"+ currentdate.getDate() + "_"
  + (currentdate.getMonth()+1)  + "_" 
  + "t"  
  + currentdate.getHours() + "_"  
  + currentdate.getMinutes() + "_" 
  + currentdate.getSeconds();
  var bodyFormData = new FormData();

  bodyFormData.append('afileName', newFileName);
  bodyFormData.append('csvData', data);
  bodyFormData.append('csvLog', ls_log);

  var s_url="";

  s_url = "https://csm2.achieversprofile.com/c/d?handler=Log";


  axios({
    method: "post",
    url: s_url,
   
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  })
    .then(function (response) {
      //handle success
      //console.log(response); w:480, H:640
   
           

    })
    .catch(function (response) {
      //handle error

      
      //saveStatus.innerHTML = response;
      //document.getElementById('save').style.visibility = "hidden";
      //console.log(response);

    });

}



function getTurningPt(an_direction){

  let n_turningPtIndex=0;
  let n_refValue=arryPoseSeq[0][n_DKpIndex].y;

  for (let s=1;s<arryPoseSeq.length;s++){

    if (an_direction>0){

      if (arryPoseSeq[s][n_DKpIndex].y < n_refValue){
        
        n_turningPtIndex = s;
        n_refValue = arryPoseSeq[s][n_DKpIndex].y;

      }


      
    }


  }
  logIt("turning pt of value: " + n_refValue + " index:" + n_turningPtIndex)
  return n_turningPtIndex;

}

class ObjDraw{
  constructor(){

    this.nCanvasHeight = window.innerHeight;
    this.nCanvasWidth =  window.innerWidth;

    this.arryColor = ["#236b1f","#f5027c","#0a157d","#8f271b","#FFFF00","#A461D3","#19f7ec","#F70000","#1df239","#FFA333"] // switch to next animation script when color exhausted
    // follow me. Like this. Do together. try.Like that. See this. 

    this.nColorIndex=0;

    //dbCtx.fillStyle =this.arryColor[ this.nColorIndex];

    this.nRefreshRate = 250;
    this.nIntervalPS = 1000/this.nRefreshRate;
    this.nAnimateDuration = 2;
    this.nAnimateFactor = 1/(this.nIntervalPS*this.nAnimateDuration);

    this.nX=0;
    this.nY=0;
    this.nShapeWidth=0;
    this.nShapeHeight=0; 

    this.nAnimationScriptIndex=0;
    this.arryAnimationScript=["lr_ud","l_dia","lr_bc","lr_lr","r_dia","ld_io"]; //,"ld_io"
    this.arryAnimationSeq=[];

    this.nShapeIndex=0;

    this.score=0;
    this.totalAssessed=0;

    this.nPhaseScore=0;
    this.nPhaseFrame=0;

    this.nCheerCkpt = Math.round(this.arryColor.length/2);
    this.nCheerCount = 0;
    this.bCheer=false;

    this.nScreenPortion=1;

    this.nTutorialSeq=0;
    this.nLastTutorialSeq=9;
    this.bTutActivated=false;
    this.nTutActivatedTime=-1;

    this.bNotEncouraged = true;

    this.isActive=false;
  }

  resetAnimationParam(){

    this.nShapeWidth = 0;
    this.nShapeHeight = 0;
    this.nCheerCount = 0;
    this.bCheer=false;



    this.nColorIndex++;

    if(this.nColorIndex === this.arryColor.length){
      this.nColorIndex=0;
      this.nScreenPortion=1;
      this.nPhaseScore=0;
      this.nPhaseFrame=0;
      this.bNotEncouraged=true;
      this.bTutActivated=false;
      this.nTutActivatedTime=-1;
      this.nAnimationScriptIndex++;
      
      if ( this.nAnimationScriptIndex === this.arryAnimationScript.length )
        this.nAnimationScriptIndex=0;            

    }


  }

  setAnimationSeq(){
    // end conditions checking. For color changing etc
    if (this.arryAnimationScript[this.nAnimationScriptIndex]==="l_dia" 
    ||this.arryAnimationScript[this.nAnimationScriptIndex]==="r_dia" ||this.arryAnimationScript[this.nAnimationScriptIndex]==="lr_ud" 
    ||this.arryAnimationScript[this.nAnimationScriptIndex]==="ld_io"
    ){

      if ((this.nShapeHeight + this.nAnimateFactor*this.nCanvasHeight*this.nScreenPortion) > this.nCanvasHeight*this.nScreenPortion){ // animation ended for this pattern

        this.resetAnimationParam();

      }


    }else if (this.arryAnimationScript[this.nAnimationScriptIndex]==="lr_lr"){

      if ((this.nShapeWidth + this.nAnimateFactor*this.nCanvasWidth) > this.nCanvasWidth){ // animation ended for this pattern

        this.resetAnimationParam();

      }

    }else if (this.arryAnimationScript[this.nAnimationScriptIndex]==="lr_bc"){

      if ((this.nShapeHeight + this.nAnimateFactor*0.8*this.nCanvasHeight*this.nScreenPortion) > 0.8*this.nCanvasHeight*this.nScreenPortion){ // animation ended for this pattern

        this.resetAnimationParam();
       

      }

    }

    // done conditional checking

    this.arryAnimationSeq=[];

    if (this.arryAnimationScript[this.nAnimationScriptIndex]==="l_dia"){

      

      this.nShapeWidth = this.nShapeWidth + this.nAnimateFactor*this.nCanvasWidth;
      this.nShapeHeight = this.nShapeHeight + this.nAnimateFactor*this.nCanvasHeight*this.nScreenPortion;

      let nY= this.nCanvasHeight - this.nCanvasHeight*this.nScreenPortion;

      if(this.nColorIndex%2===0)

        this.arryAnimationSeq.push({anX:0,anY:nY,anWidth: this.nShapeWidth, anHeight: this.nShapeHeight, anShape:this.nShapeIndex, anColor:this.nColorIndex});
      else
        this.arryAnimationSeq.push({anX:this.nCanvasWidth-this.nShapeWidth,anY:this.nCanvasHeight-this.nShapeHeight,
          anWidth: this.nShapeWidth, anHeight: this.nShapeHeight, anShape:this.nShapeIndex,anColor:this.getColor(this.nColorIndex+3)});



    }else if (this.arryAnimationScript[this.nAnimationScriptIndex]==="lr_ud"){
      

      this.nShapeWidth = this.nCanvasWidth/2;
      this.nShapeHeight = this.nShapeHeight + this.nAnimateFactor*this.nCanvasHeight*this.nScreenPortion;

      let nY= this.nCanvasHeight - this.nCanvasHeight*this.nScreenPortion;


      if(this.nColorIndex%2===0){

        // left
        this.arryAnimationSeq.push({anX:0,anY:nY,anWidth: this.nShapeWidth, anHeight: this.nShapeHeight, anShape:this.nShapeIndex,anColor:this.nColorIndex});

        //right
        this.arryAnimationSeq.push({anX:this.nShapeWidth,anY:nY + (this.nCanvasHeight*this.nScreenPortion-this.nShapeHeight),anWidth: this.nShapeWidth, 
          anHeight: this.nShapeHeight, anShape:this.nShapeIndex,anColor:this.getColor(this.nColorIndex+3)});


      }else{
        this.arryAnimationSeq.push({anX:0,anY:nY + (this.nCanvasHeight*this.nScreenPortion-this.nShapeHeight),anWidth: this.nShapeWidth, 
          anHeight: this.nShapeHeight, anShape:this.nShapeIndex,anColor:this.nColorIndex});

        //right
        this.arryAnimationSeq.push({anX:this.nShapeWidth,anY:nY,anWidth: this.nShapeWidth, 
          anHeight: this.nShapeHeight, anShape:this.nShapeIndex,anColor:this.getColor(this.nColorIndex+3)});


      }

    }else if (this.arryAnimationScript[this.nAnimationScriptIndex]==="lr_lr"){

     

      this.nShapeHeight = this.nScreenPortion*this.nCanvasHeight/2 ;
      this.nShapeWidth = this.nShapeWidth + this.nAnimateFactor*this.nCanvasWidth;
      let nY= this.nCanvasHeight - this.nCanvasHeight*this.nScreenPortion;


      if(this.nColorIndex%2===0){

        // left
        this.arryAnimationSeq.push({anX:0,anY:nY,anWidth: this.nShapeWidth, anHeight: this.nShapeHeight, anShape:this.nShapeIndex,anColor:this.nColorIndex});

        //right
        this.arryAnimationSeq.push({anX:this.nCanvasWidth-this.nShapeWidth,anY:nY+this.nShapeHeight,anWidth: this.nShapeWidth, 
        anHeight: this.nShapeHeight, anShape:this.nShapeIndex,anColor:this.getColor(this.nColorIndex+3)});


      }else{
        this.arryAnimationSeq.push({anX:this.nCanvasWidth-this.nShapeWidth,anY:nY,anWidth: this.nShapeWidth
          , anHeight: this.nShapeHeight, anShape:this.nShapeIndex,anColor:this.nColorIndex});

        //right
        this.arryAnimationSeq.push({anX:0,anY:nY+this.nShapeHeight,anWidth: this.nShapeWidth
        , anHeight: this.nShapeHeight, anShape:this.nShapeIndex,anColor:this.getColor(this.nColorIndex+3)});


      }

    }else if(this.arryAnimationScript[this.nAnimationScriptIndex]==="r_dia"){

     

      this.nShapeWidth = this.nShapeWidth + this.nAnimateFactor*this.nCanvasWidth;
      this.nShapeHeight = this.nShapeHeight + this.nAnimateFactor*this.nCanvasHeight*this.nScreenPortion;

      let nY= this.nCanvasHeight - this.nCanvasHeight*this.nScreenPortion;

      if(this.nColorIndex%2===0)

        this.arryAnimationSeq.push({anX:this.nCanvasWidth -this.nShapeWidth,anY:nY,anWidth: this.nShapeWidth
          , anHeight: this.nShapeHeight, anShape:this.nShapeIndex,anColor:this.nColorIndex});
      else
        this.arryAnimationSeq.push({anX:0,anY:this.nCanvasHeight-this.nShapeHeight,anWidth: this.nShapeWidth
          , anHeight: this.nShapeHeight, anShape:this.nShapeIndex,anColor:this.getColor(this.nColorIndex+3)});

      
//ld_io
    }else if(this.arryAnimationScript[this.nAnimationScriptIndex]==="ld_io"){

      let nMidX1,nMidX2,nMidY,nShapeWidth2,nShapeHeight2;

      nMidX1 = 0.25*this.nCanvasWidth;
      nMidX2 = 0.75*this.nCanvasWidth;
      nMidY = 0.5*this.nCanvasHeight*this.nScreenPortion;

      this.nShapeWidth = this.nShapeWidth+  this.nAnimateFactor*this.nCanvasWidth/2;
      this.nShapeHeight = this.nShapeHeight + this.nAnimateFactor*this.nCanvasHeight*this.nScreenPortion;

      nShapeWidth2 = (this.nCanvasWidth/2) - this.nShapeWidth;
      nShapeHeight2 = this.nCanvasHeight*this.nScreenPortion - this.nShapeHeight;

      let nY= this.nCanvasHeight - this.nCanvasHeight*this.nScreenPortion;


      if(this.nColorIndex%2===0){

        this.arryAnimationSeq.push({anX:nMidX1 -this.nShapeWidth/2,anY: nY +nMidY - this.nShapeHeight/2 
        ,anWidth: this.nShapeWidth, anHeight: this.nShapeHeight, anShape:this.nShapeIndex,anColor:this.nColorIndex});

        this.arryAnimationSeq.push({anX:this.nCanvasWidth/2,anY:nY 
        ,anWidth: this.nCanvasWidth/2, anHeight: this.nCanvasHeight, anShape:this.nShapeIndex,anColor:this.getColor(this.nColorIndex+2)});

        this.arryAnimationSeq.push({anX:nMidX2-nShapeWidth2/2,anY:nY +nMidY - nShapeHeight2/2
        ,anWidth: nShapeWidth2, anHeight: nShapeHeight2, anShape:this.nShapeIndex,anColor:this.getColor(this.nColorIndex+3)});

      }else{
        //swap same this.nShape for the right side

        this.arryAnimationSeq.push({anX:0,anY:nY 
        ,anWidth: this.nCanvasWidth/2, anHeight: this.nCanvasHeight, anShape:this.nShapeIndex,anColor:this.getColor(this.nColorIndex-1)});

        this.arryAnimationSeq.push({anX:nMidX1 -nShapeWidth2/2,anY:nY +nMidY - nShapeHeight2/2 
        ,anWidth: nShapeWidth2, anHeight: nShapeHeight2, anShape:this.nShapeIndex,anColor:this.nColorIndex});


        this.arryAnimationSeq.push({anX:nMidX2-this.nShapeWidth/2,anY:nY +nMidY - this.nShapeHeight/2
        ,anWidth: this.nShapeWidth, anHeight: this.nShapeHeight, anShape:this.nShapeIndex,anColor:this.getColor(this.nColorIndex+3)});


      }
        

    }else if(this.arryAnimationScript[this.nAnimationScriptIndex]==="lr_bc"){

      let nBlackOffset = 0.1* this.nCanvasHeight;

      this.nShapeWidth = this.nCanvasWidth/2;
      this.nShapeHeight = this.nShapeHeight + this.nAnimateFactor* 0.8* this.nCanvasHeight*this.nScreenPortion;

      let nY=0;

      if (this.nScreenPortion===1){
        nY=nBlackOffset;
        if (this.bTutActivated){
          this.bTutActivated=false;
          dbCtx.fillStyle ="#000000";
          dbCtx.fillRect(0,0, this.nCanvasWidth,nBlackOffset);
        }

      }
        
      else
        nY= this.nCanvasHeight - this.nCanvasHeight*this.nScreenPortion;

      if (this.nColorIndex===0){

        dbCtx.fillStyle ="#000000";
        dbCtx.fillRect(0,0, this.nCanvasWidth,this.nCanvasHeight);

      }


      if(this.nColorIndex%2===0){

        this.arryAnimationSeq.push({anX:0,anY:(this.nCanvasHeight-nBlackOffset-this.nShapeHeight)
        ,anWidth: this.nShapeWidth, anHeight: this.nShapeHeight, anShape:this.nShapeIndex,anColor:this.nColorIndex});

        this.arryAnimationSeq.push({anX:this.nShapeWidth,anY:(this.nCanvasHeight-nBlackOffset-this.nShapeHeight)
          ,anWidth: this.nShapeWidth, anHeight: this.nShapeHeight, anShape:this.nShapeIndex,anColor:this.getColor(this.nColorIndex+3)});

      }else{
        //swap same this.nShape for the right side
        this.arryAnimationSeq.push({anX:0,anY:nY
          ,anWidth: this.nShapeWidth, anHeight: this.nShapeHeight, anShape:this.nShapeIndex,anColor:this.nColorIndex});
  
          this.arryAnimationSeq.push({anX:this.nShapeWidth,anY:nY
            ,anWidth: this.nShapeWidth, anHeight: this.nShapeHeight, anShape:this.nShapeIndex,anColor:this.getColor(this.nColorIndex+3)});


      }
        

    }


  }

  getColor(anColor){

    if ( anColor>= this.arryColor.length)
      anColor = anColor - this.arryColor.length;
    if ( anColor < 0)
      anColor = this.arryColor.length-1;

    return anColor;

  }

  drawShape(){


    this.setAnimationSeq();

    for (let drawObj of this.arryAnimationSeq){

      if (drawObj.anShape===0){


        dbCtx.fillStyle =this.arryColor[drawObj.anColor];
        dbCtx.fillRect(drawObj.anX, drawObj.anY, drawObj.anWidth, drawObj.anHeight);


      }

    
    }


    
    if (this.nScreenPortion<1){

      creImage("img/"+this.arryAnimationScript[this.nAnimationScriptIndex]+this.nTutorialSeq+".JPG",0,0,this.nCanvasWidth,this.nCanvasHeight*this.nScreenPortion);

      this.nTutorialSeq++;
  
      if (this.nTutorialSeq>this.nLastTutorialSeq)
        this.nTutorialSeq=0;
  

      if (this.bNotEncouraged && (timeLapsed - this.nTutActivatedTime)>2.5){


        soundEffect.src="s"+this.nAnimationScriptIndex+".mp3";
        soundEffect.play();
        this.bNotEncouraged=false;


      }

      if (this.nTutActivatedTime > 0){

        if ( (timeLapsed - this.nTutActivatedTime)>4.5){

          startSong();
          this.nTutActivatedTime=-1;


        }

      }

    }else{

      if ( this.nColorIndex ===this.nCheerCkpt && this.nCheerCount<11 &&!this.bCheer ){

        if (Math.round(this.nPhaseScore/this.nPhaseFrame) >110)
          this.bCheer=true;
  
      }
  
      if (this.bCheer && this.nCheerCount<31){
  
        if (this.nCheerCount===0){
  
          soundEffect.src="loudCheer.mp3";
          soundEffect.play();
  
        }
  
        creImage("jb.png",0,this.nCanvasHeight/2-this.nCanvasWidth/4,this.nCanvasWidth,this.nCanvasWidth/2);
        this.nCheerCount++;
  
      }


    }
   

  }

  assess(){

    this.totalAssessed++;
    this.nPhaseFrame++;

    if(this.arryAnimationScript[this.nAnimationScriptIndex]==="r_dia"){

      this.score = this.score+ getArrayStdev(arryPoseSeq,10,"x");
      this.score = this.score+ getArrayStdev(arryPoseSeq,10,"y");

      this.nPhaseScore =this.nPhaseScore+ getArrayStdev(arryPoseSeq,10,"x");
      this.nPhaseScore = this.nPhaseScore+ getArrayStdev(arryPoseSeq,10,"y");

    }else  if(this.arryAnimationScript[this.nAnimationScriptIndex]==="l_dia"){

      this.score = this.score+ getArrayStdev(arryPoseSeq,9,"x");
      this.score = this.score+ getArrayStdev(arryPoseSeq,9,"y");

      this.nPhaseScore = this.nPhaseScore+ getArrayStdev(arryPoseSeq,9,"x");
      this.nPhaseScore = this.nPhaseScore+ getArrayStdev(arryPoseSeq,9,"y");

    }else  if(this.arryAnimationScript[this.nAnimationScriptIndex]==="lr_ud" ||
    this.arryAnimationScript[this.nAnimationScriptIndex]==="lr_bc"){

      this.score = this.score+ getArrayStdev(arryPoseSeq,9,"y");
      this.score = this.score+ getArrayStdev(arryPoseSeq,10,"y");

      this.nPhaseScore = this.nPhaseScore+ getArrayStdev(arryPoseSeq,9,"y");
      this.nPhaseScore = this.nPhaseScore+ getArrayStdev(arryPoseSeq,10,"y");

    }else  if(this.arryAnimationScript[this.nAnimationScriptIndex]==="lr_lr"){

      this.score = this.score+ getArrayStdev(arryPoseSeq,9,"x");
      this.score = this.score+ getArrayStdev(arryPoseSeq,10,"x");

      this.nPhaseScore = this.nPhaseScore+ getArrayStdev(arryPoseSeq,9,"x");
      this.nPhaseScore = this.nPhaseScore+ getArrayStdev(arryPoseSeq,10,"x");

    }else  if(this.arryAnimationScript[this.nAnimationScriptIndex]==="ld_io"){

      this.score = this.score+ getArrayStdev(arryPoseSeq,7,"x");
      this.score = this.score+ getArrayStdev(arryPoseSeq,8,"x");
      this.score = this.score+ getArrayStdev(arryPoseSeq,7,"y");
      this.score = this.score+ getArrayStdev(arryPoseSeq,8,"y");

      this.nPhaseScore = this.nPhaseScore+ getArrayStdev(arryPoseSeq,7,"x");
      this.nPhaseScore = this.nPhaseScore+ getArrayStdev(arryPoseSeq,8,"x");
      this.nPhaseScore =this.nPhaseScore+ getArrayStdev(arryPoseSeq,7,"y");
      this.nPhaseScore = this.nPhaseScore+ getArrayStdev(arryPoseSeq,8,"y");

    }

    if (this.nColorIndex>0){

      if (this.nPhaseScore/this.nPhaseFrame < 60){



        if (this.nTutActivatedTime<0 && this.bNotEncouraged){

          this.nScreenPortion=0.5;
          this.bTutActivated=true;

          this.nTutActivatedTime=timeLapsed;
          pauseSong();

        }


      }
        
      else{

        if (this.nTutActivatedTime>0){

          this.nScreenPortion=1;
          this.nTutActivatedTime=-1;
          startSong();

        }

      }

       

    }

  }

  
}

function startGame(){

  document.getElementById('af').style.display="none";
  document.getElementById('canvasContainer').style.display="block";

  
  document.documentElement.scrollTop =0;
 
  b_timer=true;
  //b_inEx=true;

  if (b_initLoad){

    setUpCapturing();
    setUpDeviceParam();
    document.getElementById('loading').style.display = "block";

  }

}

function startSong(){


  if (audioCtx.state !== "running"){
    audioCtx.resume().then(function(){

      gainNode.gain.value = 1;
      song.play();

     });

  }

}

function pauseSong(){

  if (audioCtx.state === "running"){

    audioCtx.suspend().then(function(){
      song.pause();
    });

  }
}



 async function renderResult() {

  timeLapsed = (Date.now() - startTime)/1000;

  logIt("Timelapsed: " + timeLapsed+ " n_exStartTime: " + n_exStartTime );


  if (camera!==null ){
  
    let poses = null;
  
    // Detector can be null if initialization failed (for example when loading
    // from a URL that does not exist).
    if (detector != null) {
      // FPS only counts the time it takes to finish estimatePoses.
      
  
      // Detectors can throw errors, for example when using custom URLs that
      // contain a model that doesn't provide the expected output    


  
      try {
        poses = await detector.estimatePoses(
            camera.video,
            {maxPoses: STATE.modelConfig.maxPoses, flipHorizontal: false});
      } catch (error) {
        detector.dispose();
        detector = null;
        alert(error);
      }
  
    }
      
    
    // The null check makes sure the UI is not in the middle of changing to a
    // different model. If during model change, the result is from an old model,
    // which shouldn't be rendered.
    if (poses && poses.length > 0 && timeLapsed > n_systemStartUpTime) {
    
        //camera.drawResults(poses);
  
        //document.getElementById('duration').innerText = timeLapsed;
        if (b_initLoad){
          document.getElementById('loading').style.display="none";

          let s_dt = Date.now().toString();

          s_sessionID = s_dt.substring(s_dt.length-4,s_dt.length-1);


          b_initLoad=false;
          document.getElementById('af').style.display="block";
          
    
        }
  
        data = data + "\\n" ;
      
        var p_l = getPL(poses[0].keypoints);
        poses[0].keypoints = p_l;
        var p = poses[0].keypoints;
  
        data = data+ timeLapsed  + ","+ p[0].x+ "," + p[0].y+ ","+ p[0].score+ ","
        + p[1].x+ "," + p[1].y+ ","+ p[1].score+ ","+ p[2].x+ "," + p[2].y+ ","+ p[2].score+ ","
        + p[3].x+ "," + p[3].y+ ","+ p[3].score+ ","+ p[4].x+ "," + p[4].y+ ","+ p[4].score+ ","
        + p[5].x+ "," + p[5].y+ ","+ p[5].score+ ","+ p[6].x+ "," + p[6].y+ ","+ p[6].score+ ","
        + p[7].x+ "," + p[7].y+ ","+ p[7].score+ ","+ p[8].x+ "," + p[8].y+ ","+ p[8].score+ ","
        + p[9].x+ "," + p[9].y+ ","+ p[9].score+ ","+ p[10].x+ "," + p[10].y+ ","+ p[10].score+ ","
        + p[11].x+ "," + p[11].y+ ","+ p[11].score+ ","+ p[12].x+ "," + p[12].y+ ","+ p[12].score+ ","
        + p[13].x+ "," + p[13].y+ ","+ p[13].score+ ","+ p[14].x+ "," + p[14].y+ ","+ p[14].score+ ","
        + p[15].x+ "," + p[15].y+ ","+ p[15].score+ ","+ p[16].x+ "," + p[16].y+ ","+ p[16].score+ ","
  
        data = data + timeLapsed;
        //b_showPlayer=true;
        //camera.drawCtx();
        if (b_showSkeleton)
          camera.drawResults(poses);
  
        //logIt("display mode: " + n_displayMode + " learningStage: " + b_learningStage + " showPlayer :" + b_showPlayer);
        //if ((n_displayMode<4 && b_learningStage)||b_showPlayer){


        arryPoseSeq.push(p);
        //arryPoseImg.push(camera.canvas.toDataURL());
  
        if (arryPoseSeq.length > n_poseLookBack){
          arryPoseSeq.shift();
          //arryPoseImg.shift();

          if (b_clickedDance)
            exObj.assess();
        }
          

    }
  
  
  }

  //sayComment(timeLapsed);


} // RenderResult

async function setUpCapturing(){

    //await tf.setBackend(STATE.backend);

    try{
      camera = await Camera.setupCamera(STATE.camera);
    }catch(err){
      alert(err);
    }

    detector = await createDetector();


    n_predictID = setInterval(renderResult, 450);

    //n_commentID = setInterval(justComment, 2500);



    //PlaySound(ad_background,"Background.mp3",true);
    //renderPrediction();
}

async function drawShape(){

  if (b_clickedDance)
    exObj.drawShape();  


}

async function renderPrediction() {
 
  if (b_timer){
    try{
  
      await renderResult();
      rafId = requestAnimationFrame(renderPrediction);
  
     }catch(err1){
      logIt("Error under renderPrediction:"  + err1);
      //instruct("Err in renderPrediction");
     }
        
    
  }
  
  
  };
///End External interaction

function startSequence(){

  document.getElementById('songSelect').style.display="none";

  var s_inAudible = "data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV";

  //soundEffect =new Audio();
  soundEffect.src = s_inAudible;
  soundEffect.volume=1.0;
  soundEffect.play();

  //song.src = "";

  //song =new Audio();

  

  startGame();


}

async function app() {


 b_timer = true;
 
 audioCtx = new AudioContext();
 song = new Audio("DancingQueenSlim.mp3");

 source = audioCtx.createMediaElementSource(song);

 source.connect(audioCtx.destination);

 gainNode = audioCtx.createGain();

 source.connect(gainNode).connect(audioCtx.destination);

 

 
 document.getElementById("loading").width = window.innerWidth;
 document.getElementById("loading").height = window.innerHeight/2;

document.getElementById("reward1").height = 0.3*window.innerHeight;
document.getElementById("reward2").height = 0.3*window.innerHeight;



 drawingBoard = document.getElementById("shapes");

 drawingBoard.height = window.innerHeight;
 drawingBoard.width = window.innerWidth;

 
 dbCtx = drawingBoard.getContext("2d");

 var url_string = window.location.href; //
 var url = new URL(url_string);

 var state = url.searchParams.get("state");

 if (state==="demo"){

  document.getElementById("testing").style.display="block";

  document.getElementById('testing').addEventListener("click",function(){

    s_songSelected="DancingQueenSlim.mp3";
    //s_songSelected="DancingQueenSlim.mp3";
    document.getElementById('songName').innerText="testing Dancing Queen";
    document.getElementById('songNameCN').innerText="testing Dancing Queen";
    startSequence();
    
    
  
  });



 }

startTime = Date.now();

//redirectAction();


/*

*/

document.getElementById('DQ').addEventListener("click",function(){

  s_songSelected="DancingQueen.mp3";
  //s_songSelected="DancingQueenSlim.mp3";
  document.getElementById('songName').innerText="Dancing Queen";

  document.getElementById('songNameCN').innerText="Dancing Queen";
  startSequence();
  
  

});
document.getElementById('canto').addEventListener("click",function(){

  s_songSelected="ShangiHai.mp3";
  document.getElementById('songName').innerText="上海滩";
  document.getElementById('songNameCN').innerText="上海滩";
  startSequence();
  

});
document.getElementById('hk').addEventListener("click",function(){

  s_songSelected="seeWind.mp3";
  document.getElementById('songName').innerText="望春风";
  document.getElementById('songNameCN').innerText="望春风";
  startSequence();
  
});
document.getElementById('chinese').addEventListener("click",function(){

  s_songSelected="Sky.mp3";
  document.getElementById('songName').innerText="天上人间";
  document.getElementById('songNameCN').innerText="天上人间";
  startSequence();
  
});

document.getElementById('Dance').addEventListener("click", function(){

  document.getElementById('af').style.display="none";

  song.volume=1.0;
  song.src = s_songSelected;
  
  pauseSong();
  startSong();
 

  exObj = new ObjDraw();
  
  document.getElementById('canvasDrawing').style.display="block";

  n_sceneID = setInterval(drawShape, n_refreshRate);

  document.getElementById('canvasDrawing').scrollIntoView({
    behavior: 'auto',
    block: 'center',
    inline: 'center'
  });
 

        
    song.onended = function (){
  
      clearInterval(n_predictID);
      clearInterval(n_sceneID);
    
      //camera.stopVideo();
      //n_predictID
    
      camera.stopVideo();
    
      document.getElementById("canvasDrawing").style.display="none";
    
      document.getElementById("canvasContainer").style.display="none";
    
      document.getElementById("resultScore").style.display="block";
    
      document.getElementById("SessionID").innerText = s_sessionID;
    
      //document.getElementById("totalScore").innerText = Math.round(exObj.score);
    
      let rewardTier = Math.round(exObj.score/exObj.totalAssessed);
    
      //document.getElementById("finalScore").innerText = rewardTier;
    
      document.getElementById("reward1").style.display="block";
      if (rewardTier>100){
    
        document.getElementById("reward2").style.display="block";
      }
      document.documentElement.scrollTop =0;
    
      soundEffect.src = "BellCelebrateHorn.mp3";
    
      soundEffect.play();

      let sResults = "totalAssessed: " + exObj.totalAssessed + ", totalScore:" + Math.round(exObj.score) + ", result: " + rewardTier;

      sendSuggestion(s_sessionID, sResults);
    
      }


    b_clickedDance=true;

});

document.getElementById('suggestionClick').addEventListener("click",function(){


  document.getElementById('suggestionClick').style.display="none";

  let sBtn="";

  if (document.getElementById('ideaYes').checked)
    sBtn="Idea:Yes.";
  else 
    sBtn="Idea:No.";

    if (document.getElementById('ideaYes').checked)
    sBtn+="App:Yes.";
  else 
    sBtn+="App:No.";

  sendSuggestion(s_sessionID+"form",  sBtn + document.getElementById('suggestion').value);



});

/*

n_intervalPS = 1000/n_refreshRate;

n_animationFactor = 1/(n_intervalPS*n_animateDuration);

 

document.getElementById('move').addEventListener("click", function(){

  exObj = new ObjDraw();
  //dbCtx.fillStyle = "#FF0000";
  n_sceneID = setInterval(drawShape, n_refreshRate);


});

document.getElementById('send').style.fontSize = "15px";
document.getElementById('statusBar').style.fontSize = "15px";

*/



data = "DT,nX,nY,nScore"
+",leX,leY,leScore,reX,reY,reScore,learX,learY,learScore,rearX,rearY,rearScore"
+",lsX,lsY,lsScore,rsX,rsY,rsScore,lebX,lebY,lebScore,rebX,rebY,rebScore"
+",lwX,lwY,lwScore,rwX,rwY,rwScore"
+",lhX,lhY,lhScore,rhX,rhY,rhScore,lkX,lkY,lkScore,rkX,rkY,rkScore"
+",laX,laY,laScore,raX,raY,raScore,DT";



};

app();